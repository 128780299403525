import React, { useEffect, useMemo, useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import HeroSlides from '@/components/organisms/HeroSlides'
import PropertiesList from '@/components/organisms/PropertiesList'
import ResidentialFilters from '@/components/organisms/ResidentialFilters'
import ToggleLayout from '@/components/molecules/ToggleLayout'
import { getValueFromLs, setValueToLs } from '@/utils/handleLocalStorage'
import { LOCALSTORAGE_KEYS } from '@/helpers/consts'

type ResidentialQueryType = {
	content?: {
		seo?: SeoMetagagsType
		section_hero?: HeroSlidesType
		map_central_point?: MapCentralPoint
	}
	properties?: {
		edges: { node: ResidentialPropertyType }[]
	}
}

interface Props {
	data: ResidentialQueryType
}

export default function Living({ data }: Props) {
	const [layout, setLayout] = useState<LayoutToggleType>('grid')
	const { properties, content } = data || {}
	const initialData = useMemo(() => {
		const newProperties: ResidentialPropertyType[] =
			properties?.edges.map(({ node }) => ({ ...node, type: 'residential' })) || []
		return { properties: newProperties }
	}, [properties])
	const [filteredItems, setFilteredItems] = useState(initialData.properties)

	function handleItemsChange(propertyItems: ResidentialPropertyType[]) {
		setFilteredItems(propertyItems)
	}

	function handleChangeLayout(type: LayoutToggleType) {
		setValueToLs<LayoutToggleType>(LOCALSTORAGE_KEYS.LAYOUT_MODE, type)
		setLayout(type)
	}

	useEffect(() => {
		const newLayout = getValueFromLs<LayoutToggleType>(LOCALSTORAGE_KEYS.LAYOUT_MODE, 'grid')
		if (newLayout && newLayout !== layout) {
			setLayout(newLayout)
		}
	}, [])

	return (
		<Layout>
			<div className="background residential">
				<Seo {...content?.seo} />
				<HeroSlides {...content?.section_hero} />
				<ResidentialFilters properties={initialData.properties} submitFilters={handleItemsChange}>
					<ToggleLayout activeLayout={layout} onChangeLayout={handleChangeLayout} />
				</ResidentialFilters>
				<PropertiesList properties={filteredItems} activeLayout={layout} mapSetup={content?.map_central_point}>
					<ToggleLayout activeLayout={layout} onChangeLayout={handleChangeLayout} />
				</PropertiesList>
			</div>
		</Layout>
	)
}

export const livingQuery = graphql`
	query {
		content: strapiPageResidential {
			seo {
				description
				og_image {
					url
				}
				title
			}
			section_hero {
				content {
					data {
						content
					}
				}
				bg_dark_level
				slides {
					localFile {
						id
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}

			map_central_point {
				latitude
				longitude
				zoom
			}
		}
		properties: allStrapiResidentialProperty(sort: { fields: name, order: ASC }) {
			edges {
				node {
					address
					city
					id
					name
					state
					zip
					image {
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
					coordinate {
						latitude
						longitude
					}
					size_range {
						maximum
						minimum
					}
					bedrooms {
						number_of_bedrooms
					}
					features {
						feature
					}
					external_website
				}
			}
		}
	}
`
